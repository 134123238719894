import { axios, API_ROUTES } from "@/config";

export default {
  /**
   * Consult the video in amazon s3
   * @param {string} params - Gets the hash SHA-256.
   * @param {string} name - Gets the url of video.
   */
  async find(params, name) {
    let result = await axios.get(`${API_ROUTES.video.get}/${name}`, {
      params: params,
    });

    return result;
  },
  /**
   * Consult the current server time
   */
  async timezone() {
    let result = await axios.get(`${API_ROUTES.timezone.get}`);

    return result;
  },
};
